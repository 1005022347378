<template>
  <div>
    <div class="row justify-content-start">
      <div class="col-2">
        <CounterCardComponent title="Atendimentos em Fila" :value="resume.queued">
          <template #icon>
            <span class="material-symbols-outlined">pending</span>
          </template>
        </CounterCardComponent>
      </div>
      <div class="col-2">
        <CounterCardComponent title="Atendimentos sem interação"
                              :value="resume.ended_without_interaction">
          <template #icon>
            <span class="material-symbols-outlined">pending</span>
          </template>
        </CounterCardComponent>
      </div>
      <div class="col-2">
        <CounterCardComponent title="Maior tempo de espera"
                              :value="toMinutes(resume.queue_max_time)">
          <template #icon>
            <span class="material-symbols-outlined">hourglass_empty</span>
          </template>
        </CounterCardComponent>
      </div>
      <div class="col-2">
        <CounterCardComponent title="Atendimentos finalizados" :value="resume.finished">
          <template #icon>
            <span class="material-symbols-outlined">headset_mic</span>
          </template>
        </CounterCardComponent>
      </div>
      <div class="col-2">
        <CounterCardComponent title="Quantidade de Avaliações" :value="resume.evaluation_quantity">
          <template #icon>
            <span class="material-symbols-outlined">hotel_class</span>
          </template>
        </CounterCardComponent>
      </div>
      <div class="col-2">
        <CounterCardComponent title="Nota média dos atendimentos" :value="resume.evaluation">
          <template #icon>
            <span class="material-symbols-outlined">star_half</span>
          </template>
        </CounterCardComponent>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <div class="pr-1">
          <chart
            format="pie"
            title="Tabulações"
            :data="dataTabs"
            :loading="loadingTabs"
          />
        </div>
      </div>
      <div class="col-6">
        <div class="pr-1">
          <chart
            format="pie"
            title="Tags"
            :data="dataTags"
            :loading="loadingTags"
          />
        </div>
      </div>
    </div>
    <br><br>
    <div class="pr-5">
      <chart
        format="line"
        ref="chart"
        title="Atendimentos por canal"
        :data="dataChannels"
        :loading="loadingChannels"
      />
    </div>
  </div>
</template>

<script>
import CounterCardComponent from '@/components/cards/CounterCardComponent.vue';

import Chart from '@/components/rebranding/Chart.vue';
import axios from '@/plugins/axios';
import moment from 'moment';

export default {
  name: 'OmniReportsStats',
  components: {
    Chart,
    CounterCardComponent,
  },
  props: {
    filters: {
      type: Object,
    },
    startDate: {
      type: String,
    },
    endDate: {
      type: String,
    },
  },
  computed: {
    timeWindow() {
      if (moment(this.endDate).diff(moment(this.startDate), 'days')) {
        return 'day';
      }
      return 'hour';
    },

    channels() {
      return this.$parent?.filters?.channels || [];
    },
    tabs() {
      return this.$parent?.filters?.tabs || [];
    },
    tags() {
      return this.$parent?.filters?.tags || [];
    },
  },
  data() {
    const date = new Date().toISOString().substring(0, 10);

    return {
      timeout: 0,
      loadingTabs: false,
      loadingTags: false,
      dataTabs: [],
      dataTags: [],

      loadingChannels: false,
      dataChannels: [],
      resumeEvaluation: 0,
      resumeFinished: 0,
      resume: {
        finished: 0,
        evaluation: 0,
        queued: 0,
        evaluation_quantity: 0,
        ended_without_interaction: 0,
        queue_max_time: 0,
      },
    };
  },
  mounted() {
    this.fetch();
  },
  methods: {
    fetch() {
      this.loadingTabs = true;
      this.loadingTags = true;
      this.loadingChannels = true;
      clearTimeout(this.timeout);

      // Prevent multiple requests
      this.timeout = setTimeout(() => this.fetchAll(), 200);
    },
    fetchAll() {
      this.tabulation();
      this.getTags();
      this.channel();
      this.finished();
    },
    tabulation() {
      const params = {
        start_date: this.startDate,
        end_date: this.endDate,
        time_window: this.timeWindow,
        filter: {
          channels: this.channels,
          tabs: this.tabs,
          tags: this.tags,
        },
        group_by: 'tabulation',
      };

      axios()
        .post('webchat/statistics/data', {
          start_date: this.startDate,
          end_date: this.endDate,
          time_window: this.timeWindow,
          group_by: 'tabulation',
          filter: this.filters,
        })
        .then((resp) => {
          // parse data
          this.dataTabs = resp.data.map((item) => {
            const groupBy = item?.['group_by'];

            if (!groupBy || groupBy === 'null' || groupBy === 'GENERIC') {
              item.group_by = 'Não tabulado';
            }

            console.log(
              'webchat/statistics/data',
              JSON.parse(JSON.stringify(item)),
            );

            item.values = item.values.map((value) => [value.date, value.count]);

            return item;
          });

          console.log(
            'webchat/statistics/data',
            JSON.parse(JSON.stringify(this.dataTabs)),
          );

          // console.log('chart::2', this.date);

          // this.series_line = Object.values(series);
          this.loadingTabs = false;
        });
    },
    getTags() {
      axios()
        .post('webchat/statistics/data', {
          start_date: this.startDate,
          end_date: this.endDate,
          time_window: this.timeWindow,
          group_by: 'tags',
          filter: this.filters,
        })
        .then((resp) => {
          // parse data
          this.dataTags = resp.data.map((item) => {
            const groupBy = item?.['group_by'];

            if (!groupBy || groupBy === 'null' || groupBy === 'GENERIC') {
              item.group_by = 'Sem tag';
            }

            console.log(
              'webchat/statistics/data',
              JSON.parse(JSON.stringify(item)),
            );

            item.values = item.values.map((value) => [value.date, value.count]);

            return item;
          });

          console.log(
            'webchat/statistics/data/tags',
            JSON.parse(JSON.stringify(this.dataTags)),
          );
          this.loadingTags = false;
        });
    },
    channel() {
      const params = {
        start_date: this.startDate,
        end_date: this.endDate,
        time_window: this.timeWindow,
        filter: {
          channels: this.channels,
          tabs: this.tabs,
          tags: this.tags,
        },
        group_by: 'channel',
      };

      axios()
        .post('webchat/statistics/data', {
          start_date: this.startDate,
          end_date: this.endDate,
          time_window: this.timeWindow,
          group_by: 'channel',
          filter: this.filters,
        })
        .then((resp) => {
          console.log('webchat/statistics/data', resp.data);
          // parse data
          this.dataChannels = resp.data.map((item) => {
            const groupBy = item?.['group_by'];

            if (groupBy === 'rcs') {
              item.group_by = 'RCS';
            }

            if (groupBy.indexOf('-java') !== -1) {
              item.group_by = groupBy.replace(/-java$/, '');
            }

            item.values = item.values.map((value) => [value.date, value.count]);
            return item;
          });

          // console.log('chart::2', this.date);

          // this.series_line = Object.values(series);
          this.loadingChannels = false;
        });
    },
    finished() {
      const params = {
        start_date: this.startDate,
        end_date: this.endDate,
        time_window: this.timeWindow,
        filter: {
          channels: this.channels,
          tabs: this.tabs,
          tags: this.tags,
        },
        group_by: 'channel',
      };

      axios()
        .post('webchat/statistics/finished', {
          start_date: this.startDate,
          end_date: this.endDate,
          time_window: this.timeWindow,
          group_by: 'channel',
          filter: this.filters,
        })
        .then((resp) => {
          // this.data = resp;
          // this.series_line = Object.values(series);
          // console.log('webchat/statistics/finished', resp.data);

          this.resume = resp.data;

          if (this.resume.evaluation > 0) {
            this.resume.evaluation = this.resume.evaluation.toFixed(1);
          }
        });
    },
    exportData(format) {
      this.$refs.chart.exportData(format);
    },
    toMinutes(seconds) {
      return moment.utc(seconds * 1000).format('HH:mm:ss');
    },
  },
};
</script>

<style lang="scss" scoped>
.resume {
  text-align: center;
  height: 100%;

  .resume_icon {
    width: 150px;
    margin: 0 auto;
  }

  p {
    font-size: 1.1rem;
    margin: 0.5rem 0 0 0;
  }
}
</style>
